/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { headerIconsOnVideoSX } from '@/components/content/Header/styles/headerIconsOnVideo';
import { headerIconLabelSX } from '@/components/content/Header/styles/iconLabel';
import { headerItemLinkSX } from '@/components/content/Header/styles/itemLink';
import { headerItemStackSX } from '@/components/content/Header/styles/itemStack';
import { headerMiniCartBadgeSX } from '@/components/content/Header/styles/miniCart/badge';
import { headerMiniCartContainerSX } from '@/components/content/Header/styles/miniCart/container';
import { useCart } from '@/data/Content/Cart';
import { useNotifications } from '@/data/Content/Notifications';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { useThemeSettings } from '@/styles/theme';
import { combineSX } from '@/utils/combineSX';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Badge, Breakpoint, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useEffect, useRef, useState } from 'react';

type Props = {
	mobileBreakpoint: Breakpoint;
	onVideo?: boolean;
};

export const HeaderMiniCart: FC<Props> = ({ mobileBreakpoint = 'sm', onVideo = false }) => {
	const router = useNextRouter();
	const RouteLocal = useLocalization('Routes');
	const CartLabels = useLocalization('MiniCart');
	const { message } = useNotifications();
	const theme = useTheme();
	const { getAdditive } = useThemeSettings();
	const { user } = useUser();
	const { user: { userId } = {} } = useUser();
	const { count: itemCount, mutateCart } = useCart();
	const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
	const [open, setOpen] = useState(false);
	const initialized = useRef<boolean>(false);
	const [count, setCount] = useState<number>(0);
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	useEffect(() => {
		setIsLoggedIn(() => user?.isLoggedIn ?? false);
	}, [user]);
	useEffect(() => {
		if (message.isAddToCart) {
			setOpen(true);
		}
	}, [message.isAddToCart]);
	useEffect(() => {
		if (initialized.current) {
			mutateCart();
		} else {
			initialized.current = true;
		}
	}, [mutateCart, userId]);

	// for CDN caching -- render this on client
	useEffect(() => {
		setCount(() => itemCount);
	}, [itemCount]);

	return isLoggedIn ? (
		<Stack
			sx={
				onVideo
					? combineSX([headerMiniCartContainerSX, headerIconsOnVideoSX])
					: headerMiniCartContainerSX
			}
		>
			<Stack alignItems="center" sx={headerItemStackSX}>
				<Badge badgeContent={count} color="secondary" sx={headerMiniCartBadgeSX}>
					<Linkable
						href={`/${RouteLocal.Cart.route.t()}`}
						id="header-mini-cart-icon"
						data-testid="header-mini-cart-icon"
						aria-label={CartLabels.Items.t({ count })}
						sx={headerItemLinkSX}
						tabIndex="-1"
					>
						<ShoppingCartOutlinedIcon />
					</Linkable>
				</Badge>
				<Typography sx={headerIconLabelSX}>
					<Linkable
						href={`/${RouteLocal.Cart.route.t()}`}
						id="header-mini-cart-title"
						data-testid="header-mini-cart-title"
						aria-label={CartLabels.Items.t({ count })}
						sx={headerItemLinkSX}
					>
						{CartLabels.Items.t({ count })}
					</Linkable>
				</Typography>
			</Stack>
			{isMobile === false ? (
				<Button sx={getAdditive('coverTapTarget')}>{CartLabels.Title.t()}</Button>
			) : null}
		</Stack>
	) : null;
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerNavBarDropMenuItemSX = ({
	isParent,
	isSortiment,
	isDrawer,
}: {
	isParent: boolean;
	isSortiment?: boolean;
	isDrawer?: boolean;
}): SxProps => ({
	fontSize: isParent || isSortiment ? 16 : 14,
	fontWeight: isParent || isSortiment ? 700 : 400,
	mt: isParent ? 1 : '',
	p: isParent ? 0 : '0.75rem 1rem',
	display: 'block',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textTransform: isDrawer ? 'uppercase' : 'none',
	color: 'text.primary',
	'@media (hover: hover)': {
		'&:hover': {
			color: 'primary.main',
			backgroundColor: 'transparent',
		},
	},
	'@media (hover: none)': {
		'&:focus': {
			color: 'inherit',
			backgroundColor: 'inherit',
		},
	},
});

export const headerMegaMenuItemSX = ({ isParent }: { isParent: boolean }): SxProps => ({
	fontSize: isParent ? 16 : 14,
	fontWeight: isParent ? 700 : 400,
	mt: isParent ? 1 : '',
	mb: isParent ? 1 : '',
	p: isParent ? 0 : '0.125rem 0rem',
	display: 'block',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textTransform: isParent ? 'uppercase' : 'none',
	color: 'text.primary',
	'@media (hover: hover)': {
		'&:hover': {
			color: 'primary.main',
			backgroundColor: 'transparent',
		},
	},
	'@media (hover: none)': {
		'&:focus': {
			color: 'inherit',
			backgroundColor: 'inherit',
		},
	},
});

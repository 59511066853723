/* eslint-disable complexity */
import { useProjects, useSetProject } from '@/data/Content/Project';
import { useLocalization } from '@/data/Localization';
import { ProjectContext } from '@/data/context/project';
import { CloseOutlined } from '@mui/icons-material';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import {
	Avatar,
	Box,
	Button,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Pagination,
	Skeleton,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { deliveryInformationSX } from 'presentation/components/fiwe/content/CentroCheckout/style/DeliveryInformation';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
export const Projects: FC<{
	setDrawerOpen?: Dispatch<SetStateAction<boolean>>;
	listOpen?: boolean;
}> = ({ setDrawerOpen, listOpen = false }) => {
	// projektlista
	const locProject = useLocalization('ProjectSelection');
	const [projectFilter, setProjectFilter] = useState(''); // filter med sökord för att hitta projekt
	const [currentProjectPage, setCurrentProjectPage] = useState(1); // pagenering
	const { projects, page, pages, query } = useProjects(projectFilter, currentProjectPage); // hämta projekt från jeeves

	// byte av aktivt projekt: -1 => rensa, '' => hämtar från server >
	// const [currentProject, setCurrentProject] = useState('');
	const { currentProjectId, name, deliveryInfo } = useContext(ProjectContext);
	const { setActiveProjectId } = useSetProject();
	const [showList, setShowList] = useState(listOpen);
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurrentProjectPage(value);
	};

	useEffect(() => {
		setCurrentProjectPage(1);
	}, [projectFilter]);

	const handleSetProjectClick = (projectId: string) => {
		if (currentProjectId !== projectId) {
			setActiveProjectId(projectId);
			setShowList(false);
			if (setDrawerOpen) setDrawerOpen(false);
		}
	};

	return (
		<Box>
			<Box p={setDrawerOpen ? 2 : 0}>
				{setDrawerOpen ? (
					<Stack direction="row" justifyContent="space-between">
						<Typography
							component="h6"
							variant="h4"
							sx={{
								fontFamily: 'GT-America-Condensed, GT-America, Roboto, sans-serif',
								fontWeight: 900,
							}}
						>
							{locProject.title.t()}
						</Typography>

						<IconButton onClick={() => setDrawerOpen(false)}>
							<CloseOutlined color="black" />
						</IconButton>
					</Stack>
				) : (
					<Typography variant="h2" sx={deliveryInformationSX}>
						{locProject.title.t()}
					</Typography>
				)}

				{currentProjectId ? (
					<ListItem id="currentProject" alignItems="flex-start">
						<ListItemAvatar>
							<Avatar sx={{ backgroundColor: theme.palette.primary.light }}>
								<HomeWorkIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={
								<>
									<Typography variant="strong">{currentProjectId}</Typography> {'-'} {name}
								</>
							}
							secondary={
								<Typography sx={{ fontSize: '.875rem' }}>
									{deliveryInfo?.address}
									<br />
									{deliveryInfo?.zipCode} {deliveryInfo?.city}
								</Typography>
							}
						/>
					</ListItem>
				) : (
					<>
						<Typography variant="body1" sx={{ fontSize: '.875rem' }}>
							{locProject.subtitle.t()}
						</Typography>
						<ListItem />
					</>
				)}

				<Stack direction={'row'} gap={1}>
					<Button
						variant="contained"
						size="small"
						onClick={() => {
							setShowList(!showList);
						}}
					>
						{showList
							? locProject.actions.close.t()
							: currentProjectId
							? locProject.actions.change.t()
							: locProject.actions.choose.t()}
					</Button>
					<Button
						variant="contained"
						size="small"
						disabled={!currentProjectId}
						onClick={() => handleSetProjectClick('-1')}
					>
						{locProject.actions.clear.t()}
					</Button>
				</Stack>
			</Box>

			<Collapse in={showList}>
				<Stack alignItems={'stretch'} direction={{ xs: 'column', sm: 'row' }} mt={2}>
					<Box p={2} bgcolor={theme.palette.background.primary}>
						<TextField
							type="search"
							size="small"
							label={locProject.search.label.t()}
							helperText={locProject.search.helperText.t()}
							value={projectFilter}
							onChange={(e) => setProjectFilter(e.currentTarget.value)}
							sx={{ '.MuiFormHelperText-root': { margin: 0 } }}
						/>
					</Box>

					<Stack
						flexGrow={1}
						gap={2}
						alignItems={{ xs: 'center', sm: 'flex-start' }}
						p={2}
						bgcolor={grey[50]}
					>
						<List sx={{ width: '100%', padding: 0 }}>
							{projects?.map(({ projectId, deliveryInfo, name }) => (
								<ListItemProject
									name={name}
									projectId={projectId}
									currentProjectId={currentProjectId}
									deliveryInfo={deliveryInfo}
									handleSetProjectClick={handleSetProjectClick}
									key={projectId}
								/>
							))}
							{projects === undefined ? <ListSkeleton isXs={isXs} loops={query.pageSize} /> : null}
						</List>
						{page && pages ? (
							<Pagination count={pages} page={page} onChange={handlePageChange} />
						) : null}
					</Stack>
				</Stack>
			</Collapse>
		</Box>
	);
};

const ListItemProject = ({
	name,
	projectId,
	currentProjectId,
	deliveryInfo,
	handleSetProjectClick,
}: {
	name: string;
	projectId: string;
	currentProjectId: string | null;
	deliveryInfo: { address: string; zipCode: string; city: string };
	handleSetProjectClick: (projectId: string) => void;
}) => {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<ListItemButton
			selected={currentProjectId === projectId}
			alignItems="flex-start"
			onClick={() => handleSetProjectClick(projectId)}
			key={projectId}
		>
			<ListItemAvatar>
				{currentProjectId === projectId ? (
					<Avatar sx={{ backgroundColor: theme.palette.primary.light }}>
						<HomeWorkIcon />
					</Avatar>
				) : (
					<Avatar variant="rounded" sx={{ bgcolor: 'transparent' }}>
						<HomeWorkIcon color="disabled" />
					</Avatar>
				)}
			</ListItemAvatar>
			<ListItemText
				sx={{ wordBreak: 'break-word' }}
				primary={
					<>
						<Typography variant="strong">{projectId}</Typography> {'-'} {name}
					</>
				}
				secondary={
					<Typography sx={{ fontSize: '.875rem' }}>
						{deliveryInfo.address}
						{isXs ? null : (
							<>
								<br />
								{`${deliveryInfo.zipCode}\u00A0${deliveryInfo.city}`}
							</>
						)}
					</Typography>
				}
			/>
		</ListItemButton>
	);
};

const ListSkeleton = ({ loops, isXs }: { loops: number; isXs: boolean }) => (
	<>
		{Array(loops)
			.fill(1)
			.map((card, index) => (
				<ListItem alignItems="flex-start" key={index}>
					<ListItemAvatar>
						<Skeleton variant="circular">
							<Avatar />
						</Skeleton>
					</ListItemAvatar>
					<ListItemText
						primary={<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200}></Skeleton>}
						secondary={
							<Typography sx={{ fontSize: '.875rem' }}>
								<Skeleton variant="text" sx={{ fontSize: '.875rem' }} width={200}></Skeleton>
								{isXs ? null : (
									<Skeleton variant="text" sx={{ fontSize: '.875rem' }} width={100}></Skeleton>
								)}
							</Typography>
						}
					/>
				</ListItem>
			))}
	</>
);

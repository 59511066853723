/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerIconsOnVideoSX } from '@/components/content/Header/styles/headerIconsOnVideo';
import { headerIconLabelSX } from '@/components/content/Header/styles/iconLabel';
import { headerItemStackSX } from '@/components/content/Header/styles/itemStack';
import { useLocalization } from '@/data/Localization';
import { combineSX } from '@/utils/combineSX';
import SearchIcon from '@mui/icons-material/Search';
import { Breakpoint, Stack, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
	mobileBreakpoint: Breakpoint;
	onVideo: boolean;
	searchOpen: boolean;
	setSearchOpen: (open: boolean) => void;
};

export const HeaderIconSearch: FC<Props> = ({ onVideo, searchOpen, setSearchOpen }) => {
	const HeaderLabels = useLocalization('Header');
	return (
		<Stack
			alignItems="center"
			sx={onVideo ? combineSX([headerItemStackSX, headerIconsOnVideoSX]) : headerItemStackSX}
			onClick={() => setSearchOpen(!searchOpen)}
			onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
				e.key === 'Enter' ? setSearchOpen(!searchOpen) : null
			}
			tabIndex={0}
			aria-label={HeaderLabels.Actions.Search.t()}
		>
			<SearchIcon />
			<Typography
				sx={onVideo ? combineSX([headerIconLabelSX, headerIconsOnVideoSX]) : headerIconLabelSX}
			>
				{HeaderLabels.Actions.Search.t()}
			</Typography>
		</Stack>
	);
};

import { SxProps } from '@mui/material';

export const headerNavBarItemSX: SxProps = {
	'&.MuiButton-text': {
		paddingX: 1,
		paddingY: 1,
		color: 'component.header.navbar.text',
		borderBottom: '2px solid transparent',
		fontSize: 16,
		fontWeight: 500,

		'&:hover': {
			borderBottomColor: 'primary.main',
		},

		'&:first-of-type': {
			// marginLeft: -2,
		},

		'&:is(a):not([href])': {
			cursor: 'default',
		},
	},
};

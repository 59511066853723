/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { alpha, SxProps, Theme } from '@mui/material';

export const headerNavBarDropMenuSX: SxProps = {
	borderTopRightRadius: 0,
	borderTopLeftRadius: 0,
	maxWidth: 'calc(100vw - 40px)',
	boxShadow: 2,
	backgroundColor: 'background.paper',
	color: 'background.contrastText',
	'@media (hover: hover)': {
		py: 0,
		px: 1,
	},

	'.MuiTooltip-popper[data-popper-placement*="bottom"] > &': {
		'@media (hover: hover)': {
			mt: 0,
			ml: -2,
		},
		'@media (hover: none)': {
			mt: 0.75,
		},
	},
};

export const headerMegaMenuDropMenuSX = (theme: Theme, onVideo: boolean): SxProps<Theme> => ({
	borderTopRightRadius: 0,
	borderTopLeftRadius: 0,
	// maxWidth: 'calc(100vw - 40px)',
	maxWidth: 'none',
	width: '100vw',
	// boxShadow: 2,
	boxShadow: '0 2px 4px 0 ' + alpha(theme.palette.primary.main, 0.25),
	backgroundColor: onVideo
		? alpha(theme.palette.background.paper, 0.5)
		: theme.palette.background.paper,
	color: 'background.contrastText',
	py: 2,
	px: 1,
	borderTop: '1px solid ' + alpha(theme.palette.primary.main, 0.5),

	'.MuiTooltip-popper[data-popper-placement*="bottom"] > &': {
		'@media (hover: hover)': {
			mt: 4,
			ml: -1,
			mr: 0,
		},
		'@media (hover: none)': {
			mt: 5,
		},
	},

	'& > div': {
		width: '100%',
	},
});

export const headerMegaMenuColumnSX = (theme: Theme, level: number): SxProps<Theme> => {
	const rules: SxProps<Theme> = {
		...(level === 1
			? {
					borderLeft: '3px solid ' + alpha(theme.palette.primary.main, 0.5),
					paddingLeft: '1rem',
					marginY: 1,
					flex: '0 1 14rem',

					'&:first-of-type': {
						borderLeft: 'none',
						paddingLeft: 0,
					},

					'.MuiMenuItem-root': {
						whiteSpace: 'break-spaces',
					},
			  }
			: level === 2
			? {}
			: {}),
	};

	return rules;
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright Fiwe Systems & Consulting 2024.
 */

import { useCart } from '@/data/Content/Cart';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { DATA_KEY, fetcher, update } from '@/data/Content/_Project';
import { useSettings } from '@/data/Settings';
import { ProjectContext } from '@/data/context/project';
import { getActiveOrganizationId } from '@/data/utils/getActiveOrganizationId';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { useContext } from 'react';

import useSWR from 'swr';

export const useProjects = (keyword: string, pageNumber: number) => {
	const router = useNextRouter();
	const { settings } = useSettings();
	const { storeId } = getClientSideCommon(settings, router);

	const query = {
		keyword,
		pageSize: 5,
		pageNumber,
		sortOrder: 1,
	};

	const { data: projects } = useSWR(
		storeId ? [{ storeId }, DATA_KEY, query, getActiveOrganizationId] : null,
		async ([{ storeId }]) => fetcher(true)(storeId, query),
		{ revalidateOnFocus: false }
	);

	return {
		...projects,
		query,
	};
};

export const useSetProject = () => {
	const router = useNextRouter();
	const { settings } = useSettings();
	const { storeId } = getClientSideCommon(settings, router);
	const { mutateCart } = useCart();
	const { mutateCurrentProject } = useContext(ProjectContext);

	const setActiveProjectId = (projectId: string) => {
		update(true)(storeId, projectId).then((data) => {
			mutateCart();
			mutateCurrentProject();
			return data;
		});
	};

	return {
		setActiveProjectId,
	};
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import {
	myPagesMenuButtonGroupSX,
	myPagesMenuButtonSX,
	myPagesMenuWrapperSX,
} from '@/components/content/Header/styles/myPagesMenu';
import { useLogout } from '@/data/Content/Logout';
import { useLocalization } from '@/data/Localization';
import { ProjectContext } from '@/data/context/project';
import { Button, ButtonGroup, Drawer, Stack } from '@mui/material';
import { Projects } from 'presentation/components/fiwe/blocks/Projects';
import { FC, useContext, useState } from 'react';

export const MyPagesMenu: FC<{
	onVideo?: boolean;
}> = ({ onVideo }) => {
	const AccountLabels = useLocalization('PersonalInformation');
	const HeaderLabels = useLocalization('Header');
	const { handleLogout, settings } = useLogout();
	const { hasProjects, currentProjectId } = useContext(ProjectContext);

	const [projectDrawer, setProjectDrawer] = useState(false);

	return (
		<Stack direction="row" justifyContent="center" sx={myPagesMenuWrapperSX(onVideo)}>
			<ButtonGroup variant="text" color={onVideo ? 'white' : 'black'} sx={myPagesMenuButtonGroupSX}>
				<Button href="/dashboard" sx={myPagesMenuButtonSX}>
					{HeaderLabels.Actions.Dashboard.t()}
				</Button>
				<Button href="/account" sx={myPagesMenuButtonSX}>
					{HeaderLabels.Actions.MyAccount.t()}
				</Button>
				<Button href="/order-history" sx={myPagesMenuButtonSX}>
					{HeaderLabels.Actions.Orders.t()}
				</Button>
				<Button href="/invoices" sx={myPagesMenuButtonSX}>
					{HeaderLabels.Actions.Invoices.t()}
				</Button>
				{hasProjects ? (
					<Button onClick={() => setProjectDrawer(true)} sx={myPagesMenuButtonSX}>
						{HeaderLabels.Actions.Projects.t()}
						{currentProjectId !== null ? `: ${currentProjectId}` : null}
					</Button>
				) : null}
				{settings?.csrSession ? null : (
					<Button onClick={handleLogout} sx={myPagesMenuButtonSX}>
						{AccountLabels.SignOutButton.t()}
					</Button>
				)}
			</ButtonGroup>
			<Drawer anchor={'right'} open={projectDrawer} onClose={() => setProjectDrawer(false)}>
				<Projects listOpen={true} setDrawerOpen={setProjectDrawer} />
			</Drawer>
		</Stack>
	);
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme, lighten } from '@mui/material';

export const headerTopSX = (theme: Theme, onVideo: boolean): SxProps<Theme> => ({
	borderBottom: onVideo ? '1px solid transparent' : `1px solid ${theme.palette.primary.main}`,
});

export const headerContainerSX: SxProps<Theme> = () => ({});

export const customHeaderContainerSX = (
	theme: Theme,
	isVideo: boolean,
	onVideo: boolean
): SxProps<Theme> => {
	const defaultRules: SxProps<Theme> = {
		backgroundColor: onVideo ? 'transparent' : 'component.header.top.background',
		color: onVideo ? 'white' : 'black',
		boxShadow: onVideo ? 'none' : '0 0 1px 0 #00000040',
		top: 0,
		// position: headerBreak({ mobile: 'sticky', desktop: 'relative' }),
		position: isVideo ? 'fixed' : 'sticky',
		width: '100%',
		// zIndex: headerBreak({ mobile: `${theme.zIndex.appBar}`, desktop: 'unset' }),
		zIndex: `${theme.zIndex.appBar}`,

		'& img': {
			height: { md: 38, sm: 32, xs: 20 },
		},

		'& + main': isVideo
			? {
					'& > .MuiContainer-maxWidthXl': {
						paddingLeft: 0,
						paddingRight: 0,
						maxWidth: 'none',
					},

					'& .content-videoHeight': {
						position: 'relative',
						height: '100vh', // 'calc(100vh - 8.125rem)',
						width: '100%',
						background: lighten(theme.palette.primary.main, 0.6),
					},
			  }
			: {},
	};

	const isVideoRules: SxProps<Theme> = {};
	const onVideoRules: SxProps<Theme> = {
		'& > .MuiPaper-root': {
			backgroundColor: 'transparent',
		},
	};

	return {
		...defaultRules,
		...isVideoRules,
		...onVideoRules,
	};
};

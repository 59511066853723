/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { PRODUCT_LIST_DEFAULT_PAGE_SIZE } from '@/data/constants/catalog';
import { ParsedUrlQuery } from 'querystring';

export const getProductListQueryParameters = (query: ParsedUrlQuery) => {
	const { offset: _offset = 0, facetLimit, minPrice, maxPrice, facet, orderBy, searchTerm } = query;
	let offset = Number(_offset);
	if (Number.isNaN(offset) || offset < 0) {
		offset = 0;
	}
	return {
		offset,
		limit: PRODUCT_LIST_DEFAULT_PAGE_SIZE, // default, not implemented, since Emerald does not have this
		...(facetLimit !== undefined && { facetLimit }),
		...(minPrice !== undefined && { minPrice: Number(minPrice) }),
		...(maxPrice !== undefined && { maxPrice: Number(maxPrice) }),
		...(facet !== undefined && { facet }),
		...(orderBy !== undefined && { orderBy }),
		...(searchTerm !== undefined && {
			searchTerm: formatSearchTerm(searchTerm),
		}),
	};
};

const formatSearchTerm = (searchTerm?: unknown): string | undefined => {
	if (searchTerm === undefined || searchTerm === null) return undefined;

	const term = searchTerm.toString();
	const containsNumbersXNumbers = /\b\d+[xX]\d+\b/.test(term); // check for "numbers x numbers" pattern

	if (!containsNumbersXNumbers) {
		return decodeURIComponent(term);
	}

	const startsWithQuote = term.startsWith("'") ? '' : "'";
	const endsWithQuote = term.endsWith("'") ? '' : "'";
	return `${startsWithQuote}${decodeURIComponent(term)}${endsWithQuote}`;
};

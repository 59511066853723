import { Linkable, LinkWrap } from '@/components/blocks/Linkable';
import { LoggedIn } from '@/components/blocks/LoggedIn';
import { HeaderNavBarDropMenu } from '@/components/content/Header/parts/NavBarDropMenu';
import { customHeaderNavBarContainerSX } from '@/components/content/Header/styles/navBar/container';
import { headerNavBarItemSX } from '@/components/content/Header/styles/navBar/item';
import { useNavigation } from '@/data/Navigation';
import { Container, Paper, Stack } from '@mui/material';
import { FC, Fragment } from 'react';

export const HeaderNavBar: FC<{ onVideo?: boolean }> = ({ onVideo }) => {
	const { navigation } = useNavigation();
	return (
		<Paper sx={customHeaderNavBarContainerSX(!!onVideo)} elevation={0} square>
			<Container>
				<Stack
					direction="row"
					justifyContent="center"
					useFlexGap
					spacing={{ xs: 1, md: 1.5, xl: 2 }}
				>
					{navigation
						?.filter(({ url }) => !!url)
						.map(({ label, url, children }) => (
							<Fragment key={`${label}${url}`}>
								<LoggedIn>
									{label === 'Sortiment' ? (
										<LinkWrap href={url}>
											<HeaderNavBarDropMenu tree={children} label={label} />
										</LinkWrap>
									) : (
										<Linkable href={url} type="button" sx={headerNavBarItemSX}>
											{label}
										</Linkable>
									)}
								</LoggedIn>
								<LoggedIn is={false}>
									<Linkable href={url} type="button" sx={headerNavBarItemSX}>
										{label}
									</Linkable>
								</LoggedIn>
							</Fragment>
						))}
				</Stack>
			</Container>
		</Paper>
	);
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2024.
 */

import { SxProps } from '@mui/material';

export const headerItemLinkSX: SxProps = {
	color: 'text.primary',

	'& > svg': {
		opacity: 0.75,
	},
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const headerIconsOnVideoSX: SxProps<Theme> = (theme: Theme) => ({
	'&, & a, & svg': {
		color: theme.palette.common.white,
	},
});

import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps, Theme } from '@mui/material';

export const headerNavBarContainerSX: SxProps<Theme> = () => ({});

export const customHeaderNavBarContainerSX = (onVideo: boolean): SxProps<Theme> => ({
	display: headerBreak({ mobile: 'none', desktop: 'block' }),
	backgroundColor: onVideo ? 'transparent' : 'component.header.navbar.background',
	'& .MuiButtonBase-root.MuiButton-root': {
		color: onVideo ? 'white.main' : 'component.header.navbar.color',
	},
	flex: '1 0 auto',
});

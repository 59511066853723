/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { LoggedIn } from '@/components/blocks/LoggedIn';
import { Img } from '@/components/blocks/MaterialImage';
import { HeaderIconSearch } from '@/components/content/Header/parts/IconSearch';
import { HeaderMiniCart } from '@/components/content/Header/parts/MiniCart';
import { HeaderMobileDrawer } from '@/components/content/Header/parts/MobileDrawer';
import { HeaderMobileToggleButton } from '@/components/content/Header/parts/MobileToggleButton';
import { MyPagesMenu } from '@/components/content/Header/parts/MyPagesMenu';
import { HeaderNavBar } from '@/components/content/Header/parts/NavBar';
import { HeaderSearch } from '@/components/content/Header/parts/Search';
import { customHeaderContainerSX, headerTopSX } from '@/components/content/Header/styles/container';
import { ID } from '@/data/types/Basic';
import { Box, Container, Paper, Stack, useTheme } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';

import { createContext } from 'react';

export type HeaderContextType = {
	onVideo: boolean;
	megaMenuOpen: boolean;
	setMegaMenuOpen: Dispatch<SetStateAction<boolean>>;
};

export const HeaderContext = createContext<HeaderContextType>({} as HeaderContextType);

export const Header: FC<{
	id: ID;
}> = ({ id }) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const [megaMenuOpen, setMegaMenuOpen] = useState(false);
	const toggleDrawer = useCallback(
		(open?: boolean) => () => {
			setDrawerOpen(open !== undefined ? open : (current) => !current);
		},
		[]
	);
	const theme = useTheme();
	// const isXL = useMediaQuery(theme.breakpoints.up('xl'));
	// const { contentItems } = useHeader(id); // this contains information from the layout about the header. Such as espots.
	const [onVideo, setOnVideo] = useState(false);

	const listenScrollEvent = () => {
		if (window.scrollY > 4) {
			return setOnVideo(false);
		} else {
			return setOnVideo(id === 'headerVideo');
		}
	};

	useEffect(() => {
		listenScrollEvent();
		if (id === 'headerVideo') {
			window.addEventListener('scroll', listenScrollEvent);
		}
	});

	return (
		<HeaderContext.Provider value={{ onVideo, megaMenuOpen, setMegaMenuOpen }}>
			<Paper
				id="header"
				component="header"
				sx={customHeaderContainerSX(theme, id === 'headerVideo', onVideo)}
				elevation={0}
				square
			>
				<style>
					<LoggedIn is={false}>
						{`:root {
								--header-height: 126px;
							}`}
					</LoggedIn>
					<LoggedIn>
						{`:root {
								--header-height: 173px;
							}`}
					</LoggedIn>
				</style>
				<Box sx={headerTopSX(theme, onVideo)}>
					<Container maxWidth="xl">
						<Stack direction="row" alignItems="center" spacing={2}>
							<HeaderMobileToggleButton toggleDrawer={toggleDrawer} open={drawerOpen} />
							<HeaderMobileDrawer toggleDrawer={toggleDrawer} open={drawerOpen} />
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								spacing={2}
								flexGrow={1}
								sx={{ padding: { md: '2.5rem 0', sm: '2rem 0', xs: '1.5rem 0' } }}
							>
								<Linkable href={'/'} sx={{ flex: '1 0 auto', lineHeight: 0 }}>
									<Img
										alt={'logo'}
										src={'/CentroLogo/Centro_LOGO_VIT.svg'}
										sx={onVideo ? {} : { filter: 'invert(1)' }}
									/>
								</Linkable>
								<HeaderNavBar onVideo={onVideo} />
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="flex-end"
									spacing={{ xs: 2, lg: 3 }}
									sx={{ flex: '1 0 auto' }}
								>
									<HeaderIconSearch
										mobileBreakpoint="md"
										onVideo={onVideo}
										searchOpen={searchOpen}
										setSearchOpen={setSearchOpen}
									/>
									{/* <LoggedIn>
									{hasProjects ? (
										<HeaderIconProjects onVideo={onVideo} activeProject={currentProjectId} />
									) : null}
								</LoggedIn> */}
									{/* <HeaderAccount mobileBreakpoint="md" onVideo={onVideo} /> */}
									<HeaderMiniCart mobileBreakpoint="md" onVideo={onVideo} />
								</Stack>
							</Stack>
						</Stack>
					</Container>
				</Box>
				<HeaderSearch searchOpen={searchOpen} />
				<LoggedIn>{!megaMenuOpen ? <MyPagesMenu onVideo={onVideo} /> : null}</LoggedIn>
			</Paper>
		</HeaderContext.Provider>
	);
};

import { useUser } from '@/data/User';
import { FC, useEffect, useState } from 'react';

type Props = {
	is?: boolean;
	children?: JSX.Element | JSX.Element[] | string | null;
};

export const LoggedIn: FC<Props> = ({ is = true, children }) => {
	const { user } = useUser();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	useEffect(() => {
		setIsLoggedIn(() => user?.isLoggedIn ?? false);
	}, [user]);
	return is === isLoggedIn ? <>{children}</> : null;
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps } from '@mui/material';

export const headerIconLabelSX: SxProps = {
	display: headerBreak({ mobile: 'none', desktop: 'block' }),
	textAlign: 'center',
	fontSize: '.75em',
	textTransform: 'uppercase',
	// fontWeight: '700',
	// fontFamily: 'GT-America-Condensed, GT-America, Roboto, sans-serif',
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerSearchContainerSX, headerSearchSX } from '@/components/content/Header/styles/search';
import { useSearchNavigation } from '@/data/Content/SearchNavigation';
import { useLocalization } from '@/data/Localization';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Container, IconButton, InputAdornment, TextField } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';

type Option = {
	label: string;
	href?: string;
	identifier: string;
};

export const HeaderSearch: FC<{ mobile?: boolean; searchOpen?: boolean }> = ({
	mobile,
	searchOpen,
}) => {
	const { searchValue, suggest, onInputChange, onSubmit } = useSearchNavigation();
	const SearchNLS = useLocalization('SearchBar');
	const uniqueId = `type-ahead-site-search-${mobile ? 'mobile' : 'desktop'}`;
	const options = useMemo<Option[]>(
		() =>
			suggest
				.map(({ identifier, entry }) =>
					identifier && entry
						? entry?.map(({ label, href }) => ({ label, href, identifier })) || []
						: []
				)
				.flat(1),
		[suggest]
	);

	useEffect(() => {
		if (searchOpen) {
			const input = document.getElementById(uniqueId);
			if (input) {
				input.focus();
			}
		}
	}, [searchOpen, uniqueId]);

	const autocompleteElement = (
		<Autocomplete
			freeSolo
			disableClearable
			onChange={(_, item) => onSubmit(typeof item === 'string' ? { label: item } : item)}
			onInputChange={onInputChange}
			groupBy={({ identifier }) => identifier}
			options={options}
			sx={headerSearchSX({ isMobile: mobile })}
			id={uniqueId}
			renderInput={({ inputProps, ...params }) => (
				<TextField
					variant={mobile ? 'outlined' : 'standard'}
					inputProps={{ ...inputProps, 'data-testid': uniqueId }}
					{...params}
					InputProps={{
						...params.InputProps,
						placeholder: SearchNLS.SearchField.t().toLocaleUpperCase(),
						slotProps: {
							input: { role: undefined, 'aria-expanded': undefined },
						},
						size: mobile ? 'small' : 'medium',
						type: 'search',
						onKeyDown: (e) => {
							if (e.code === 'Enter' && e.currentTarget.value) {
								onSubmit({ label: e.currentTarget.value });
							}
						},
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label={SearchNLS.SearchField.t()}
									onClick={() => onSubmit({ label: searchValue })}
									edge="end"
								>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	);
	return searchOpen ? (
		<Container maxWidth="xl" sx={headerSearchContainerSX({ isMobile: mobile })}>
			{autocompleteElement}
		</Container>
	) : null;
};

import { SxProps, Theme, alpha } from '@mui/material';

export const myPagesMenuButtonGroupSX: SxProps = {
	columnGap: { xs: 1, sm: 0.625, md: 4 },
	padding: { xs: '0 .5rem', sm: '0 1rem', md: '0 1rem' },
	flexWrap: 'wrap',
	justifyContent: 'center',

	'& > .MuiButton-root': {
		borderRight: 0,
	},
};

export const myPagesMenuButtonSX: SxProps = {
	textTransform: 'uppercase',
	fontFamily: 'GT-America-Condensed, GT-America, Roboto, sans-serif',
	fontWeight: 700,
	lineHeight: { xs: 1.2, sm: 1.5, md: 1.7 },
	fontSize: { xs: '.875rem', sm: '1rem', md: '1.25rem' },

	'&:hover': {
		color: 'primary.main',
	},
};

export const myPagesMenuWrapperSX = (onVideo = false): SxProps<Theme> => ({
	bgcolor: (theme) => (onVideo ? 'transparent' : alpha(theme.palette.primary.main, 0.2)),
	// borderBottom: onVideo ? '1px solid' : 'none',
	// borderColor: alpha(grey[900], 0.05),
});

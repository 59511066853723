/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps, Theme } from '@mui/material';

export const headerSearchContainerSX = ({ isMobile }: { isMobile?: boolean }): SxProps<Theme> => {
	const defaultRules: SxProps<Theme> = {
		display: 'flex',
		justifyContent: { xs: 'center', sm: 'flex-end' },
		padding: isMobile ? { mobile: 0, desktop: 2 } : 2,
	};
	const isVideoRules: SxProps<Theme> = {};
	const onVideoRules: SxProps<Theme> = {};

	return {
		...defaultRules,
		...isVideoRules,
		...onVideoRules,
	};
};

export const headerSearchSX = ({ isMobile }: { isMobile?: boolean }): SxProps<Theme> => ({
	width: isMobile ? 'auto' : '45ch',
	display: headerBreak({ mobile: 'flex', desktop: 'inherit' }),
	...desktop,
});

const desktop = {
	'.MuiFormControl-root': {
		'&.MuiFormControl-fullWidth': {
			padding: 0,
			backgroundColor: 'component.header.top.background',
		},
	},

	'.MuiInputBase-root': {
		'&.MuiInput-root': {
			padding: 1,
			fontSize: 'medium',
		},
	},

	'.MuiOutlinedInput-root': {
		height: '48px',
		backgroundColor: 'component.header.top.background',

		'&:has(> input:focus)': {
			backgroundColor: 'background.paper',
		},

		'&:not(:has(> input:hover)) fieldset': {
			borderColor: (theme: Theme) =>
				theme.palette.component.header.top.search.hiddenWhenNotFocused
					? `transparent`
					: `rgba(0, 0, 0, 0.23)`,
		},
	},

	'.MuiOutlinedInput-input': {
		'&::-webkit-input-placeholder': {
			opacity: (theme: Theme) =>
				theme.palette.component.header.top.search.hiddenWhenNotFocused ? 0 : 0.8,
		},

		'&:hover::-webkit-input-placeholder': (theme: Theme) =>
			theme.palette.component.header.top.search.hiddenWhenNotFocused
				? {
						opacity: 0.8,
				  }
				: {},

		'&:focus::-webkit-input-placeholder': (theme: Theme) =>
			theme.palette.component.header.top.search.hiddenWhenNotFocused
				? {
						opacity: 0.8,
				  }
				: {},
	},

	'.MuiSvgIcon-root': {
		color: 'component.header.top.text',
	},
};
